<template>
  <div>
    <div class="m-wrapper">
      <div class="taps-container" style=" direction: ltr;">
        <div class="m-tab-nav rounded-corners">
          <router-link
            v-for="link in links"
            :to="{ name: link.href }"
            exact
            :key="link.name"
            tag="div"
            class="m-tab rounded-corners"
          >
            <span>{{ link.name }}</span>
            <font-awesome-icon :icon="link.icon" />
          </router-link>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      links: [
        {
          name: "إضافة صفحة",
          href: "ManageBots.AddPage",
          icon: "plus"
        },
        {
          name: "غيّر الصفحة",
          href: "ManageBots.BrowsePages",
          icon: "sync-alt"
        }
      ],
      pages: []
    };
  },
  beforeCreate() {
    this.$router.replace({ name: "ManageBots.BrowsePages" });
  },
  beforeRouteUpdate(to, from, next) {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // For example, for a route with dynamic params `/foo/:id`, when we
    // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
    // will be reused, and this hook will be called when that happens.
    // has access to `this` component instance.
    if (to.name === "Dashboard.Manage") {
      next({ name: "ManageBots.BrowsePages" });
    } else {
      next();
    }
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    // { name: 'Commenter.NewCommenter' }
    if (to.name === "Dashboard.Manage") {
      next({ name: "ManageBots.BrowsePages" });
    } else {
      next();
    }
  }
};
</script>

<style scoped>
.main-container {
  text-align: center;
}

.main-container .section-title {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 60px;
  margin-top: 60px;
}

.page-info-card .page-picture {
  /* box-shadow: 0px 0px 25px -12px rgba(0,0,0,1); */
  padding: 2px;
  border: 1px dashed #a8a8a8;
  border-radius: 50px;
  width: 50%;
  height: auto;
}

.page-info-card .picture-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
